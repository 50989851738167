<template>
    <div class="flex flex-col" >
        <TableTemplate v-if="loading == false" :candelete="false" canview="/orders/" :columns="columns" :items="orders"></TableTemplate>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    name: 'OrderList',
    data: () => ({
        loading:false,
        orders: [],
        columns:[
            {
                id:'id',
                label:'ID',
            },
            {
                id:'total',
                label:'Total',
                custom: 'DisplayTotal'
            },
            {
                id:'user_id',
                label:'Ordered By',
                custom: 'DisplayCustomer'
            },
            {
                id:'payment_profile_id',
                label:'Payment Profile',
                custom: 'DisplayPaymentProfile'
            },
            {
                id:'payment_method_id',
                label:'Payment Method',
                custom: 'DisplayPaymentMethod'
            },
            {
                id:'status',
                label:'Status',
            },
        ],
    }),
    computed: {
    },
    props: ['model'],
    methods: {
        DisplayPaymentProfile(item) {
            if(item.payment_profile) return item.payment_profile.uid + ' : ' + item.payment_profile.email
            return item.payment_profile_id
        },
        DisplayPaymentMethod(item) {
            if(item.payment_method) return item.payment_method.uid + ' : ' + item.payment_method.card_number
            return item.payment_method_id
        },
        DisplayTotal(item) {
            return '$'+parseFloat(item.total).toFixed(2)
        },
        DisplayAgency(item) {
            if(item.agency) return item.agency.id + ' : ' + item.agency.name
            return 'N/A'
        },
        DisplayCustomer(item) {
            if(item.customer) return item.customer.email
            return 'N/A'
        },
        getOrders() {
            if(this.model) {
                this.loading = true
                this.$requests.get(`${this.model.route}/${this.model.id}/orders`,
                {
                    success: (re) => {
                        this.loading = false
                        this.orders = re.data.items
                        return true
                    },
                })
            }
        },
        getDate(datetime) {
            return moment(datetime).format('MMMM Do YYYY');
        },
    },
    watch: {
    },
    created() {
        this.getOrders()
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
