<template>
    <div class="pt-16 px-2 ">
        <h1 class="text-red text-lg mb-6">
            Create Agency
        </h1>
        <div class="" >
            <FormTemplate @response="formResponse" button="Create" method="post" action="/agency/create" :formdata="formData">
                
                <TextInput type="text" label="Agency Name" v-model="item.name" :required="false" ></TextInput>
                <TextInput type="text" label="Agency Email" v-model="item.email" :required="false" ></TextInput>
                <TextInput type="text" label="Agency Phone" v-model="item.phone" :required="false" ></TextInput>
                <TextInput type="text" label="Agency Address" v-model="item.address" :required="false" ></TextInput>
                <TextInput type="text" label="Agency City" v-model="item.city" :required="false" ></TextInput>
                <TextInput type="text" label="Agency State" v-model="item.state" :required="false" ></TextInput>
                <TextInput type="text" label="Agency Country" v-model="item.country" :required="false" ></TextInput>
                <TextInput type="text" label="Agency Zipcode" v-model="item.zip" :required="false" ></TextInput>
            </FormTemplate>
        </div>
    </div>
</template>

<script>
import Create from '../../../mixins/Create'

export default {
    name: 'CreateService',
    mixins: [Create],
    data: () => ({
        back: '/agency',
        item: {
            name: '',
            phone: '',
            email: '',
            address: '',
            city: '',
            state: '',
            country: '',
            zip: '',
        },
        accountSearchParams: {
            'model': 'Account',
            'fields': ['id','name'],
            'action': '/search/',
        },
        action: '/agency/',
    }),
    computed: {
        formData() {
            return {
                'agency':this.item,
            }
        },
    },
    props: [],
    methods: {
        generatePw() {
            this.$set(this.item,'password',Math.random().toString(36).slice(-10))
        },
        accountDisplay(item) {
            return 'ID: ' + item.id + ' Name: ' + item.name;
        },
        accountSelected(evt) {
            let index = this.item.accounts.findIndex((account)=>{return account.id == evt.item.id})
            if(index < 0) this.item.accounts.push(evt.item);
        },
        accountRemoved(evt) {
            this.item.accounts.splice(evt.index,1);
        },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
