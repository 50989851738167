<template>
    <div class="pt-16 px-2 ">
        <h1 class="text-red text-lg mb-6 title">
            Edit Agency <span class="pap-check">{{papCheck}}</span>
        </h1>
        <div class="" v-if="item">
            <SlideoutTabs default_width="half" :model="{id:item.id,type:'Account',route: 'agency',subscriptions_route:'/agency'}" :contents="tab_content"  orientation="top-0 bottom-0 right-0" position="fixed">
            </SlideoutTabs>
            <FormTemplate @response="formResponse" button="Save" method="post" action="/agency" :formdata="formData">
                <TextInput type="text" label="Name" v-model="item.name" :required="false" ></TextInput>
                <TextInput type="text" label="Phone" v-model="item.phone" :required="false" ></TextInput>
                <TextInput type="email" label="Email" v-model="item.email" :required="false" ></TextInput>
                <TextInput type="text" label="Address" v-model="item.address" :required="false" ></TextInput>
                <TextInput type="text" label="Address 2" v-model="item.address_2" :required="false" ></TextInput>
                <label for="country">Country</label>
                <country-select id="country" class="w-full mx-4 my-1 text-normal text-open border " v-model="item.country" :country="item.country" topCountry="US" />
                <label for="state">State</label>
                <region-select id="state" class="w-full mx-4 my-1 text-normal text-open border "  v-model="item.state" :country="item.country" :region="item.state" />
                <TextInput type="text" label="Zip" v-model="item.zip" :required="false" ></TextInput>
                <TextInput type="text" label="City" v-model="item.city" :required="false" ></TextInput>
                <SearchSelectInput class="my-6" @item:removed="serviceRemoved" @item:selected="serviceSelected" :params="serviceSearchParams" :multiple="true" :hide_label="false" label="Services" v-model="item.services" :optiondisplay="serviceDisplay" :hideSelected="true"></SearchSelectInput>
                <div class="p-6 border border-yellow">
                    <div class="flex" v-for="(service,i) in item.services" :key="i">
                        <!-- service: {{service}} -->
                        <div class="flex-1 font-bold">
                            {{service.name}}
                            <!-- <router-link class="hover:text-red hover:underline" :to="`/customers/${customer.id}`">{{customerDisplay(customer)}}</router-link> -->
                        </div>
                        <div class="flex-1">
                            <SelectInput label="Type" v-model="service.pivot.type" :options="[{label:'Subscription',value:'Subscription'},{label:'Manual',value:'Manual'}]" :required="true" ></SelectInput>
                        </div>
                        <div class="flex-1">
                            <button title="Remove Item" type="button" @click="RemoveServiceItem(i)" class="ml-5 text-red font-bold">
                                X
                            </button>
                        </div>
                    </div>
                </div>

                <Collapsable class="my-6" label="Users">
                    <SearchSelectInput @item:removed="customerRemoved" @item:selected="customerSelected" :params="customerSearchParams" :multiple="true" :hide_label="true" label="Users" v-model="item.customers" :optiondisplay="customerDisplay" :hideSelected="true"></SearchSelectInput>
                    <div class="p-6 border border-yellow">
                        <div class="flex" v-for="(customer,i) in item.customers" :key="i">
                            <div class="flex-1 font-bold">
                                <router-link class="hover:text-red hover:underline" :to="`/customers/${customer.id}`">{{customerDisplay(customer)}}</router-link>
                            </div>
                            <div class="flex-1">
                                <SelectInput class="flex-1" label="Team" v-model="customer.pivot.team_id" :options="[{label:'Executive',value:'1'},{label:'Development',value:'2'},{label:'Design',value:'3'}, {label:'Customer',value:'4'}]" :required="false" ></SelectInput>
                                <SelectInput label="Role" v-model="customer.pivot.role_id" :options="[{label:'Owner',value:'1'},{label:'Manager',value:'2'},{label:'Standard',value:'3'}, {label:'Client',value:'4'}]" :required="false" ></SelectInput>
                            </div>
                            <div class="flex-1">
                                <button title="Remove Item" type="button" @click="RemoveCustomerItem(i)" class="ml-5 text-red font-bold">
                                    X
                                </button>
                            </div>
                        </div>
                    </div>
                </Collapsable>
                <SearchSelectInput @item:removed="domainRemoved" @item:selected="domainSelected" :params="domainSearchParams" :multiple="false" label="Domain" v-model="item.domain" :optiondisplay="domainDisplay"></SearchSelectInput>
                <p>
                    Created On  {{getDate(item.created_at)}}
                </p>
                <p>
                    Last Updated On  {{getDate(item.updated_at)}}
                </p>
            </FormTemplate>
        </div>
    </div>
</template>

<script>
import View from '../../../mixins/View'
import moment from 'moment'
import Notes from '../../../components/dashboard/Notes'
import Licenses from '../../../components/dashboard/licenses/List'
import Orders from '../../../components/dashboard/orders/List'
import Subscriptions from '../../../components/dashboard/Subscriptions'

export default {
    name: 'Agency',
    mixins: [View],
    data: () => ({
        customerSearchParams: {
            'model': 'Customer',
            'fields': ['id','first_name','last_name','email'],
            'action': '/search/',
        },
        serviceSearchParams: {
            'model': 'Service',
            'fields': ['id','name'],
            'action': '/search/',
        },
        domainSearchParams: {
            'model': 'Domain',
            'fields': ['id','domain'],
            'conditions': {
                'account': {
                    'where': 'account_id',
                    'comparison': '=',
                    'value': false,
                },   
            },
            'action': '/search/',
        },
        parameter: 'agency_id',
        action: '/agency/',
        tab_content: {
            'licenses': {
                label: 'Licenses',
                icon: '/assets/licenses.png',
                component: Licenses,
                top: '200px',
            },
            'subscriptions': {
                label: 'Subscriptions',
                icon: '/assets/subscriptions.png',
                component: Subscriptions,
                top: '300px',
            },
            'orders': {
                label: 'Orders',
                icon: '/assets/orders.png',
                component:Orders,
                top: '400px',
            },
            'notes': {
                label: 'Notes',
                icon: '/assets/notes.png',
                component: Notes,
                top: '500px',
            },
        }
    }),
    computed: {
        formData() {
            return {
                'agency':this.item,
            }
        },
        papCheck() {
          const papStatus = this.PAP;
          let papMsg;

          if ( papStatus == false ) {
            return
          }

          if ( papStatus == 'Active' ) {
            papMsg = 'Active PAP Member'
          }
          if ( papStatus == 'Former' ) {
            papMsg = 'Former PAP Member'
          }

          return papMsg;
        }
    },
    props: [],
    methods: {
        getDate(date) {
            return moment(date).format('MMMM Do YYYY, h:mm:ss a');
        },        
        serviceDisplay(item) {
            return 'Name: '+item.name
        },
        serviceSelected(evt) {
            evt.item.pivot = {
                account_id: this.item.id,
                type: "Subscription",
                service_id: evt.item.id,
            }
            let index = this.item.services.findIndex(service=>{return service.id == evt.item.id})
            if(index < 0) this.$set(this.item.services,this.item.services.length,evt.item)
        },
        serviceRemoved(evt) {
            this.item.services.splice(evt.index,1);
        },
        RemoveServiceItem(index) {
            this.item.services.splice(index,1)
        },
        customerDisplay(item) {
            return 'Name: '+item.first_name +' '+item.last_name+' | Email: '+item.email;
        },
        customerSelected(evt) {
            evt.item.pivot = {
                account_id: this.item.id,
                role_id: 3,
            }
            let index = this.item.customers.findIndex(customer=>{return customer.id == evt.item.id})
            if(index < 0) this.$set(this.item.customers,this.item.customers.length,evt.item)
        },
        RemoveCustomerItem(index) {
            this.item.customers.splice(index,1)
        },
        customerRemoved(evt) {
            this.item.customers.splice(evt.index,1);
        },
        domainDisplay(item) {
            return 'ID: ' + item.id + ' Domain: ' + item.domain;
        },
        domainSelected(evt) {
            this.$set(this.item,'domain_id',evt.item.id)
            this.$set(this.item,'domain',evt.item)
        },
        domainRemoved() {
            this.$delete(this.item,'domain_id')
            this.$delete(this.item,'domain')
        }
    },
    watch: {
    },
    created() {
        this.domainSearchParams.conditions.account.value = this.$route.params.agency_id
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
  .title{
    position:relative;
  }
  .pap-check{
    position: absolute;
    right:0;
    font-size: .45em;
  }
</style>
