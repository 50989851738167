<template>
    <div class="flex flex-col" >
        <div @click="$router.push({path:`/licenses/${license.id}`})" class="flex-1 border-b-2 cursor-pointer py-2 px-4 text-purple text-sm" v-for="(license,i) in licenses" :key="i">
            ID: {{license.id}} Key: {{license.key}} <span v-if="license.product" >Product: {{license.product.name}}</span>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    name: 'LicenseList',
    data: () => ({
        licenses: [],
    }),
    computed: {
    },
    props: ['model'],
    methods: {
        getLicenses() {
            if(this.model) {
                this.$requests.get(`${this.model.route}/${this.model.id}/licenses`,
                {
                    success: (re) => {
                        this.licenses = re.data.items
                        return true
                    },
                })
            }
        },
        getDate(datetime) {
            return moment(datetime).format('MMMM Do YYYY');
        },
    },
    watch: {
    },
    created() {
        this.getLicenses()
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
